<template>
  <div class="product">
    <div class="zmhd">
      <div class="zmhd_box">
        <p>政民互动</p>
        <p>微信综合服务平台</p>
        <div class="btn" @click="chat">在线咨询</div>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_row">
        <div class="hxld_item">
          <div class="hxld_item_head">
            <span class="label">功能快速迭代</span>
            <img
              class="gnksdd_icon"
              src="@/assets/images/product/zmhd/gnksdd.png"
            />
          </div>
          <div class="hxld_item_body">
            <span>响应时代要求<br />新增惠民功能，组织线上活动</span>
          </div>
        </div>
        <div class="hxld_item">
          <div class="hxld_item_head">
            <span class="label">功能快速迭代</span>
            <img
              class="tyjsbz_icon"
              src="@/assets/images/product/zmhd/tyjsbz.png"
            />
          </div>
          <div class="hxld_item_body">
            <span>减少重复建设、节约行政资金</span>
          </div>
        </div>
      </div>
    </div>
    <div class="zhzwfw">
      <div class="zhzwfw_box">
        <img
          class="img"
          id="img"
          src="@/assets/images/product/zmhd/zhzwfw.png"
        />
        <div class="title" id="title">
          <img
            class="icon"
            src="@/assets/images/product/zmhd/zhzwfw_icon.png"
          />
          <p class="first_line">整合政务服务</p>
          <p class="second_line">
            整合全区（县）的政务服务功能<br />提供服务接入、服务共享等功能接口
          </p>
        </div>
      </div>
    </div>
    <div class="smrz">
      <div class="title" id="title2">
        <img class="icon" src="@/assets/images/product/zmhd/smrz_icon.png" />
        <p class="first_line">自然人/法人实名认证</p>
        <p class="second_line">
          无需注册账号密码<br />信息根据用户身份精准公开
        </p>
      </div>
      <img class="img2" id="img2" src="@/assets/images/product/zmhd/smrz.png" />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    chat () {
      this.$chat()
    },
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var zhzwfwH = $('.zhzwfw').offset().top
      var smrzH = $('.smrz').offset().top
      if (
        h > zhzwfwH - 500
      ) {
        $('#img').addClass('animation')
        $('#title').addClass('animation')
      }
      if (h > smrzH - 500) {
        $('#img2').addClass('animation')
        $('#title2').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
